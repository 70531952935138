import {
  deleteVehicleFail,
  deleteVehicleRequest,
  deleteVehicleSuccess,
  newVehicleFail,
  newVehicleRequest,
  newVehicleSuccess,
  updateVehicleFail,
  updateVehicleRequest,
  updateVehicleSuccess,
  vehicleFail,
  vehicleRequest,
  vehicleSuccess,
} from "../slices/vehicleSlice";
import {
  vehiclesListsFail,
  vehiclesListsRequest,
  vehiclesListsSuccess,
} from "../slices/vehiclesSlice";
import { apiClient } from "./utils";

export const getVehicle = (id) => async (dispatch) => {
  try {
    dispatch(vehicleRequest());
    const { data } = await apiClient.get(`/api/v1/vehicle/${id}`);
    dispatch(vehicleSuccess(data));
  } catch (error) {
    dispatch(vehicleFail(error.response.data.message));
  }
};

export const getVehicleList = (keyword, currentPage) => async (dispatch) => {
  try {
    dispatch(vehiclesListsRequest());
    let link = `/api/v1/vehicles?page=${currentPage}`;

    if (keyword) {
      link += `&keyword=${keyword}`;
    }
    const { data } = await apiClient.get(link);
    dispatch(vehiclesListsSuccess(data));
  } catch (error) {
    dispatch(vehiclesListsFail(error.response.data.message));
  }
};

export const createVehicle = (vehicleData,navigate) => async (dispatch) => {
  try {
    dispatch(newVehicleRequest());
    console.log('log')
    const { data } = await apiClient.post("/api/v1/vehicle/register", vehicleData);
    // console.log('first', data)
    dispatch(newVehicleSuccess(data));
    navigate("/vehicle/success");
  } catch (error) {
    dispatch(newVehicleFail(error.response.data.message));
  }
};

export const updateVehicle = (id, vehicleData) => async (dispatch) => {
  try {
    dispatch(updateVehicleRequest());
    const { data } = await apiClient.put(`/api/v1/vehicle/${id}`, vehicleData);
    dispatch(updateVehicleSuccess(data));
  } catch (error) {
    dispatch(updateVehicleFail(error.response.data.message));
  }
};

export const deleteVehicle = (id) => async (dispatch) => {
  try {
    dispatch(deleteVehicleRequest());
    await apiClient.delete(`/api/v1/vehicle/${id}`);
    dispatch(deleteVehicleSuccess());
  } catch (error) {
    dispatch(deleteVehicleFail(error.response.data.message));
  }
};

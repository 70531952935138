import { createSlice } from "@reduxjs/toolkit";

const memberSlice = createSlice({
  name: "memberList",
  initialState: {
    loading: false,
    memberList: {},
    isMemberDeleted: false,
    isMemberUpdated: false,
    isMemberCreated: false,
  },
  reducers: {
    memberRequest(state, action) {
      return {
        loading: true,
      };
    },
    memberSuccess(state, action) {
      return {
        loading: false,
        memberList: action.payload.memberReg,
      };
    },
    memberFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    newMemberRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newMemberSuccess(state, action) {
      return {
        ...state,
        loading: false,
        memberList: action.payload.memberReg,
        isMemberCreated: true,
      };
    },
    newMemberFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isMemberCreated: false,
      };
    },
    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearProduct(state, action) {
      return {
        ...state,
        memberList: {},
      };
    },
    updateMemberRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateMemberSuccess(state, action) {
      return {
        ...state,
        loading: false,
        memberList: action.payload.memberReg,
        isMemberUpdated: true,
      };
    },
    updateMemberFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearVehicleUpdated(state, action) {
      return {
        ...state,
        isMemberUpdated: false,
      };
    },
    deleteMemberRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteMemberSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isMemberDeleted: true,
      };
    },
    deleteMemberFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearMemberDeleted(state, action) {
      return {
        ...state,
        isMemberDeleted: false,
      };
    },
  },
});

const { actions, reducer } = memberSlice;

export const {
    memberRequest,
    memberSuccess,
    memberFail,
    newMemberRequest,
    newMemberSuccess,
    newMemberFail,
    clearError,
    clearMemberList,
    updateMemberRequest,
    updateMemberSuccess,
    updateMemberFail,
    clearMemberUpdated,
    deleteMemberRequest,
    deleteMemberSuccess,
    deleteMemberFail,
    clearMemberDeleted,
} = actions;

export default reducer;



import { createSlice } from "@reduxjs/toolkit";

const membersSlice = createSlice({
  name: "members",
  initialState: {
    loading: false,
  },
  reducers: {
    membersListsRequest(state, action) {
      return {
        loading: true,
      };
    },
    membersListsSuccess(state, action) {
      return {
        loading: false,
        members: action.payload.allmemberlist,
        membersCount: action.payload.count,
        resPerPage: action.payload.resPerPage,
      };
    },
    membersListsFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
  },
});

const { actions, reducer } = membersSlice;

export const { membersListsFail, membersListsRequest, membersListsSuccess } =
  actions;

export default reducer;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

function ContactUs() {
  const scriptURL = "https://script.google.com/macros/s/AKfycbxV75_8oR7wGPHc8Re8tiH21nofLA3KV97S0K9K9hiu8NVd-Z8YLoI-1acNkMcsQJ_x/exec";

  useEffect(() => {
    const form = document.forms["contact-form"];
    const submitBtn = form.querySelector('button[type="submit"]');

    const handleSubmit = (e) => {
      e.preventDefault();
      submitBtn.disabled = true;

      fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then((response) => response.json())
        .then((data) => {
          if (data.result === "success") {
            Swal.fire({
              title: "Success!",
              text: "Message sent successfully!",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } else {
            console.error("Error:", data.error);
          }
        })
        .catch((error) => {
          console.error("Error!", error.message);
        });
    };

    form.addEventListener("submit", handleSubmit);

    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, []);

  return (
    <div>
      {/* ======= Breadcrumbs ======= */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Contact</h2>
            <ol>
              <li>
                <Link to="/home" style={{ color: "#009970" }}>Home</Link>
              </li>
              <li>Contact</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Breadcrumbs */}
      <section id="contact" className="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-4" data-aos="fade-right">
              <div className="section-title">
                <h2>Contact</h2>
                <p>
                  Need a hand steering your work to reach your desired
                  destination then reach out to us.
                </p>
              </div>
            </div>
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay={100}>
              <iframe
                title="Google Maps"
                style={{ border: 0, width: "100%", height: 270 }}
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3941.048106822907!2d77.06798997501816!3d8.967714691091166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwNTgnMDMuOCJOIDc3wrAwNCcxNC4wIkU!5e0!3m2!1sen!2sin!4v1712749843520!5m2!1sen!2sin"
                frameBorder={0}
                allowFullScreen=""
              />

              <div className="info mt-4">
                <i className="bi bi-geo-alt" />
                <h4>Location:</h4>
                <p>
                  NO 327 KAMALALAYAM BUILDINGS THENMALA, Kollam, Kollam, Kerala,
                  India, 691308
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <div className="info">
                    <i className="bi bi-envelope" />
                    <h4>Email:</h4>
                    <p>thenmalaraja2020@gmail.com</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="info w-100 mt-4">
                    <i className="bi bi-phone" />
                    <h4>Call:</h4>
                    <p>+91 9496943471</p>
                  </div>
                </div>
              </div>
              <form
                method="post"
                className="php-email-form mt-4"
                name="contact-form"
                id="contact-form"
                action=""
              >
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="Name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      required=""
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="Email"
                      id="email"
                      placeholder="Your Email"
                      required=""
                    />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="Contact"
                    id="Contact"
                    placeholder="Contact Number"
                    required=""
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="Subject"
                    id="subject"
                    placeholder="Subject"
                    required=""
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="Message"
                    rows={5}
                    placeholder="Message"
                    required=""
                    defaultValue={""}
                  />
                </div>
                <div className="text-center">
                  <button id="submit-btn" type="submit" value="submit">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;

import React from 'react'
import './header.css'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 footer-contact">
            <h3>IndianDriversSociety</h3>
            <p>
              NO 327 KAMALALAYAM BUILDINGS THENMALA, Kollam, Kollam, Kerala,
              India, 691308
              <strong>Phone:</strong> +91 9496943471
              <br />
              <strong>Email:</strong> thenmalaraja2020@gmail.com
              <br />
            </p>
          </div>
          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <Link to="/home">Home</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <Link to="/about">About us</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <Link to="/donation">Donation</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <Link to="/registration">Registration</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 footer-links">
            <h4>Other Links</h4>
            <ul>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <Link to="Error404">Terms of service</Link>
              </li>
              <li>
                <i className="bx bx-chevron-right" />{" "}
                <Link to="Error404">Privacy policy</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container d-md-flex py-4">
      <div className="me-md-auto text-center text-md-start">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span>IndianDriversSociety</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by <Link to="https://bizpole.in/">Bizpole</Link>
        </div>
      </div>
      <div className="social-links text-center text-md-right pt-3 pt-md-0">
        <Link to="/#" className="twitter">
          <i className="bx bxl-twitter" />
        </Link>
        <Link to="/#" className="facebook">
          <i className="bx bxl-facebook" />
        </Link>
        <Link to="/#" className="instagram">
          <i className="bx bxl-instagram" />
        </Link>
        <Link to="/#" className="google-plus">
          <i className="bx bxl-skype" />
        </Link>
        <Link to="/#" className="linkedin">
          <i className="bx bxl-linkedin" />
        </Link>
      </div>
    </div>
  </footer>
  )
}

export default Footer;

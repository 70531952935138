import React from 'react';
import { Link } from 'react-router-dom';
import qr from '../../assects/Exampl.jpeg'

function VehicleSuccess() {
  return (
    <div style={{marginTop:"7rem", minHeight:"70vh", display: "flex", flexDirection: "column", alignItems: "center"}}>
      <h1>Payment Successful!</h1>
      <p>Thank you for your payment.</p>
      <div style={{textAlign: "center"}}>
        <h2>Payment Options:</h2>
        <div>
          <p>Scan the QR code to make payment</p>
          <img style={{maxWidth:"250px"}} src={qr} alt="QR code" />
        </div>
        <div>
          <p>Use the payment link provided</p>
        </div>
        <div>
          <p>Transfer to the following account number: XXXXXXXX</p>
        </div>
        <Link to="/">Back to Home</Link>
      </div>
    </div>
  );
}

export default VehicleSuccess;



// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Button from 'react-bootstrap/Button';
// import './header.css'; // Ensure to import the CSS

// function Header() {
//   const location = useLocation();
//   const [activeLink, setActiveLink] = useState(location.pathname);

//   useEffect(() => {
//     setActiveLink(location.pathname);
//   }, [location.pathname]);

//   const handleNavClick = (path) => {
//     setActiveLink(path);
//   };

//   return (
//     <Navbar id="header" expand="lg" className="bg-body-tertiary fixed-top custom-navbar header">
//       <Container>
//         <Navbar.Brand href="/" className="custom-brand">
//           <span style={{ color: "#009970" }}>IndianDriversSociety</span>
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="ms-auto">
//             <Nav.Link
//               href="/"
//               className={`custom-nav-link ${activeLink === '/' ? 'active' : ''}`}
//               onClick={() => handleNavClick('/')}
//             >
//               Home
//             </Nav.Link>
//             <Nav.Link
//               href="/about"
//               className={`custom-nav-link ${activeLink === '/about' ? 'active' : ''}`}
//               onClick={() => handleNavClick('/about')}
//             >
//               About
//             </Nav.Link>
//             <Nav.Link
//               href="/donation"
//               className={`custom-nav-link ${activeLink === '/donation' ? 'active' : ''}`}
//               onClick={() => handleNavClick('/donation')}
//             >
//               Donation
//             </Nav.Link>
//             <Nav.Link
//               href="/registration"
//               className={`custom-nav-link ${activeLink === '/registration' ? 'active' : ''}`}
//               onClick={() => handleNavClick('/registration')}
//             >
//               Registration
//             </Nav.Link>
//             <Nav.Link
//               href="/contact"
//               className={`custom-nav-link ${activeLink === '/contact' ? 'active' : ''}`}
//               onClick={() => handleNavClick('/contact')}
//             >
//               Contact
//             </Nav.Link>
//             <Button href='/about' variant="outline-success" className="custom-get-started getstarted">Get Started</Button>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default Header;

import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import './header.css'; // Ensure to import the CSS

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleNavClick = (path) => {
    setActiveLink(path);
  };

  return (
    <Navbar id="header" expand="lg" className="bg-body-tertiary fixed-top custom-navbar header">
      <Container>
        <Navbar.Brand as={Link} to="/" className="custom-brand">
          <span style={{ color: "#009970" }}>IndianDriversSociety</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link
              as={Link}
              to="/"
              className={`custom-nav-link ${activeLink === '/' ? 'active' : ''}`}
              onClick={() => handleNavClick('/')}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about"
              className={`custom-nav-link ${activeLink === '/about' ? 'active' : ''}`}
              onClick={() => handleNavClick('/about')}
            >
              About
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/donation"
              className={`custom-nav-link ${activeLink === '/donation' ? 'active' : ''}`}
              onClick={() => handleNavClick('/donation')}
            >
              Donation
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/registration"
              className={`custom-nav-link ${activeLink === '/registration' ? 'active' : ''}`}
              onClick={() => handleNavClick('/registration')}
            >
              Registration
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className={`custom-nav-link ${activeLink === '/contact' ? 'active' : ''}`}
              onClick={() => handleNavClick('/contact')}
            >
              Contact
            </Nav.Link>
            <Button as={Link} to="/about" variant="outline-success" className="custom-get-started getstarted">
              Get Started
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

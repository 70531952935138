import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { getMember } from "../../../actions/memberActions";
import { useReactToPrint } from "react-to-print";
import Loader from "../../layouts/Loader";
import "./Dashboard.css";
import { Dropdown } from "react-bootstrap";
import { BsPersonFill } from "react-icons/bs";

function DetailedMemberView() {
  const componentRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { memberList, loading } = useSelector((state) => state.memberState);

  useEffect(() => {
    dispatch(getMember(id));
  }, [dispatch, id]);

  return (
    <div className="container-fluid">
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Logo */}
          <div className="logo">
            <h1 className="text-light">IndianDriversSociety</h1>
          </div>

          {/* Profile Dropdown */}
          <Dropdown alignRight>
            <Dropdown.Toggle
              variant="light"
              id="dropdown-basic"
              className="dropdown-item"
            >
              <BsPersonFill className="profile-pic" />
              <span>ADMIN</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/c-panel">
                Profile <i className="bi bi-person"></i>
              </Dropdown.Item>
              <Dropdown.Item href="/AdminLogin">
                Logout <i className="bi bi-box-arrow-right"></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <div className="row">
        {/* Sidebar */}
        <aside className="col-lg-3 sidebar">
          <div className="profile">
            <h1 className="text-center bold">Member Details</h1>
            <h4>Admin</h4>
            <p>
              <span className="icon user-icon" style={{ color: "green" }}>
                Online
              </span>
            </p>
          </div>
          <nav className="text-center dashboard-nav">
            <ul>
              <li>
                <Link to="/c-panel">Dashboard</Link>
              </li>
              <li>
                <Link to="/c-panel/members_list">Membership List</Link>
                <ul>
                  <li>
                    <Link to="" className="active">
                      Member Details
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/c-panel/vehicles_list">Vehicles List</Link>
              </li>
            </ul>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="col-lg-9 main-content">
          {loading ? (
            <Loader />
          ) : (
            <div className="memberview bordered-content">
              <div className="container bordered-content" ref={componentRef}>
                <div className="logo-section d-flex justify-content-start align-items-center mb-4">
                  <img
                    className="logo-img"
                    src="/assets/img/IDS_logo.png"
                    alt="logo"
                  />
                  <h3 id="member" className="ms-3 mb-0">IndianDriversSociety</h3>
                </div>
                <h5 id="member">Member Information</h5>
                <div>
                  <table className="member-table bordered-table">
                    <tbody>
                      <tr>
                        <th>Driver Name</th>
                        <td>{memberList.drivername}</td>
                      </tr>
                      <tr>
                        <th>Aadhaar Card Number</th>
                        <td>{memberList.aadharCardNumber}</td>
                      </tr>
                      <tr>
                        <th>Driving License Number</th>
                        <td>{memberList.drivingLicenseNumber}</td>
                      </tr>
                      <tr>
                        <th>House No., Street Address</th>
                        <td>{memberList.addressLineOne}</td>
                      </tr>
                      <tr>
                        <th>District</th>
                        <td>{memberList.district}</td>
                      </tr>
                      <tr>
                        <th>State/Province</th>
                        <td>{memberList.state}</td>
                      </tr>
                      <tr>
                        <th>ZIP / Postal Code</th>
                        <td>{memberList.postalCode}</td>
                      </tr>
                      <tr>
                        <th>Country</th>
                        <td>{memberList.country}</td>
                      </tr>
                      <tr>
                        <th>Contact Number</th>
                        <td>{memberList.contactNumber}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="edit-download-btn container">
                <button
                  className="submit-btn"
                  type="button"
                  onClick={handlePrint}
                >
                  <i className="bi bi-download"></i> DOWNLOAD
                </button>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default DetailedMemberView;

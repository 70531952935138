import React, { useEffect } from "react";
import CountUp from 'react-countup';
import './HomeStyle.css'
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";

function Home() {
  const scriptURL = "https://script.google.com/macros/s/AKfycbxV75_8oR7wGPHc8Re8tiH21nofLA3KV97S0K9K9hiu8NVd-Z8YLoI-1acNkMcsQJ_x/exec";

  useEffect(() => {
    const form = document.forms["contact-form"];
    const submitBtn = form.querySelector('button[type="submit"]');

    const handleSubmit = (e) => {
      e.preventDefault();
      submitBtn.disabled = true;

      fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then((response) => response.json())
        .then((data) => {
          if (data.result === "success") {
            Swal.fire({
              title: "Success!",
              text: "Message sent successfully!",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } else {
            console.error("Error:", data.error);
          }
        })
        .catch((error) => {
          console.error("Error!", error.message);
        });
    };

    form.addEventListener("submit", handleSubmit);

    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, []);
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = './assets/img/Court_Order.pdf';
    link.download = 'Court_Order.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div>
   {/* ======= Hero Section ======= */}
   <section id="hero" className="d-flex align-items-center">
   <div
     className="container text-center position-relative"
     data-aos="fade-in"
     data-aos-delay={200}
   >
     <h1>We Steer The Wheels To A Better Tomorrow</h1>
     {/* <h2>We are team of talented designers making websites with Bootstrap</h2> */}
     <Link to="#about" className="btn-get-started scrollto">
       Get Started
     </Link>
   </div>
 </section>
 {/* End Hero */}
 <main id="main">
   {/* ======= About Section ======= */}
   <section id="about" className="about">
     <div className="container">
       <div className="row content">
         <div
           className="col-lg-6"
           data-aos="fade-right"
           data-aos-delay={100}
         >
           <h2>About Us</h2>
           <h3>Get to know who we really are</h3>
           <img src="assets/img/IDS_logo.png" alt="IDS Logo" />
         </div>
         <div
           className="col-lg-6 pt-4 pt-lg-0"
           data-aos="fade-left"
           data-aos-delay={200}
         >
           <p>
             Welcome to the Indian Drivers Society, a community established
             in 2017 by the able leadership Mr Nagarajan. With the core
             objective of enhancing road safety, advocating for drivers'
             rights, and fostering a culture of responsible driving across
             India. Our society is composed of individuals from diverse
             backgrounds united by a shared commitment to promoting safer
             roads and supporting drivers nationwide.
           </p>
           {/* <ul>
   <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequa</li>
   <li><i class="ri-check-double-line"></i> Duis aute irure dolor in reprehenderit in voluptate velit</li>
   <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in</li>
 </ul> */}
           <p>
             Through our website, you can learn about our origins, mission,
             and the dedicated team driving our initiatives forward. We
             offer valuable resources such as comprehensive road safety
             tips tailored to Indian driving conditions, information on
             drivers' rights, and access to exclusive membership benefits.
             Stay updated on our upcoming events, workshops, and campaigns
             aimed at raising awareness and advocating for improved road
             infrastructure.
           </p>
         </div>
       </div>
     </div>
   </section>
   {/* End About Section */}
   {/* ======= Counts Section ======= */}
   <section id="counts" className="counts">
 <div className="container">
   <div className="row counters">
     <div className="col-lg-3 col-6 text-center">
       <CountUp start={0} end={10} duration={1}>
         {({ countUpRef }) => (
           <span ref={countUpRef} className="purecounter" />
         )}
       </CountUp>
       <p>Members</p>
     </div>

     <div className="col-lg-3 col-6 text-center">
       <CountUp start={0} end={2} duration={1}>
         {({ countUpRef }) => (
           <span ref={countUpRef} className="purecounter" />
         )}
       </CountUp>
       <p>Location</p>
     </div>

     <div className="col-lg-3 col-6 text-center">
       <CountUp start={0} end={2} duration={1}>
         {({ countUpRef }) => (
           <span ref={countUpRef} className="purecounter" />
         )}
       </CountUp>
       <p>Branches</p>
     </div>

     <div className="col-lg-3 col-6 text-center">
       <CountUp start={0} end={1} duration={1}>
         {({ countUpRef }) => (
           <span ref={countUpRef} className="purecounter" />
         )}
       </CountUp>
       <p>Events</p>
     </div>
   </div>
 </div>
</section>

   {/* End Counts Section */}
   {/* ======= Services Section ======= */}
   <section id="services" className="services section-bg">
     <div className="container">
       <div className="row">
         <div className="col-lg-4">
           <div className="section-title" data-aos="fade-right">
             <h2>What Do We Offer</h2>
             <p>
               Join our society, our mission is to ensure your rights are
               protected and foster a culture of responsible driving. We
               offer:
             </p>
           </div>
         </div>
         <div className="col-lg-8">
           <div className="row">
             <div className="col-md-6 d-flex align-items-stretch">
               <div
                 className="icon-box"
                 data-aos="zoom-in"
                 data-aos-delay={100}
               >
                 <div className="icon">
                   <i className="bx bxl-dribbble" />
                 </div>
                 <h4>
                   <Link to="/#">
                     Be part of a group of people who are on the same ride
                     as you are.
                   </Link>
                 </h4>
                 {/* <p>Be part of a group of people who are on the same ride as you are.</p> */}
               </div>
             </div>
             <div className="col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
               <div
                 className="icon-box"
                 data-aos="zoom-in"
                 data-aos-delay={200}
               >
                 <div className="icon">
                   <i className="bx bx-file" />
                 </div>
                 <h4>
                   <Link to="/#">Ensure your rights are protected.</Link>
                 </h4>
                 {/* <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p> */}
               </div>
             </div>
             <div className="col-md-6 d-flex align-items-stretch mt-4">
               <div
                 className="icon-box"
                 data-aos="zoom-in"
                 data-aos-delay={300}
               >
                 <div className="icon">
                   <i className="bx bx-tachometer" />
                 </div>
                 <h4>
                   <Link to="/#">
                     Provide resources for the betterment of the members
                     and give financial backing.
                   </Link>
                 </h4>
                 {/* <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p> */}
               </div>
             </div>
             <div className="col-md-6 d-flex align-items-stretch mt-4">
               <div
                 className="icon-box"
                 data-aos="zoom-in"
                 data-aos-delay={400}
               >
                 <div className="icon">
                   <i className="bx bx-world" />
                 </div>
                 <h4>
                   <Link to="/#">
                     A proactive society composed of nationwide members and
                     active in fulfilling the mission of the society.
                   </Link>
                 </h4>
                 {/* <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p> */}
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
   {/* End Services Section */}
   <section>
     <div className="container section-title">
       <h2>Court Orders</h2>
       <p>
         Interim Injunction by the High Court to permit truck-carrying rock
         products from Tenkasi and Thirunelveli.
       </p>
       <br />
       <button
        className="pdf-download-button"
         onClick={() => window.open('./assets/img/Court_Order.pdf')}
         >
        View PDF
       </button>

       {/* <Link
         to="./assets/img/Court_Order.pdf"
         download="Court_Order.pdf"
         id="pdf-download-link"
       ></Link> */}
       <button className="download-btn" onClick={handleDownload}>Download PDF</button>
       
     </div>
   </section>
   {/* ======= Portfolio Section ======= */}
   <section id="portfolio" className="portfolio">
     <div className="container">
       <div className="section-title" data-aos="fade-left">
         <h2>News And Events</h2>
         <p>
           Here's some of the instances we worked for the growth of
           society.
         </p>
       </div>
       <div
         className="row portfolio-container"
         data-aos="fade-up"
         data-aos-delay={200}
       >
         <div className="col-lg-4 col-md-6 portfolio-item filter-app">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events1.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events1.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" />  */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-web">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events6.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events6.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         {/* Add other portfolio items here */}
         <div className="col-lg-4 col-md-6 portfolio-item filter-app">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events4.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events4.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-card">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events5.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events5.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-web">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events8.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events8.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-app">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events16.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events16.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-card">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events3.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events3.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-card">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events7.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events7.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-web">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events12.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events12.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-card">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events14.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events14.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-card">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events9.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events9.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
         <div className="col-lg-4 col-md-6 portfolio-item filter-web">
           <div className="portfolio-wrap">
             <img
               src="assets/img/News&Events/news&events11.jpeg"
               className="img-fluid"
               alt=""
             />
             <div className="portfolio-info">
               <div className="portfolio-links">
                 <Link
                   to="assets/img/News&Events/news&events11.jpeg"
                   data-gallery="portfolioGallery"
                   className="portfolio-lightbox"
                 >
                   {/* <i className="bx bx-plus" /> */}
                 </Link>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
   {/* ======= Contact Section ======= */}
   <section id="contact" className="contact">
     <div className="container">
       <div className="row">
         <div className="col-lg-4" data-aos="fade-right">
           <div className="section-title">
             <h2>Contact</h2>
             <p>
               Need a hand steering your work to reach your desired
               destination then reach out to us.
             </p>
           </div>
         </div>
         <div className="col-lg-8" data-aos="fade-up" data-aos-delay={100}>
           {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3941.048106822907!2d77.06798997501816!3d8.967714691091166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwNTgnMDMuOCJOIDc3wrAwNCcxNC4wIkU!5e0!3m2!1sen!2sin!4v1712749843520!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
           <iframe
             title="map"
             style={{ border: 0, width: "100%", height: 270 }}
             src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3941.048106822907!2d77.06798997501816!3d8.967714691091166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwNTgnMDMuOCJOIDc3wrAwNCcxNC4wIkU!5e0!3m2!1sen!2sin!4v1712749843520!5m2!1sen!2sin"
             frameBorder={0}
             allowFullScreen=""
           />
           <div className="info mt-4">
             <i className="bi bi-geo-alt" />
             <h4>Location:</h4>
             <p>
               NO 327 KAMALALAYAM BUILDINGS THENMALA, Kollam, Kollam,
               Kerala, India, 691308
             </p>
           </div>
           <div className="row">
             <div className="col-lg-6 mt-4">
               <div className="info">
                 <i className="bi bi-envelope" />
                 <h4>Email:</h4>
                 <p>thenmalaraja2020@gmail.com</p>
               </div>
             </div>
             <div className="col-lg-6">
               <div className="info w-100 mt-4">
                 <i className="bi bi-phone" />
                 <h4>Call:</h4>
                 <p>+91 9496943471</p>
               </div>
             </div>
           </div>
           <form
             method="post"
             className="php-email-form mt-4"
             name="contact-form"
             id="contact-form"
           >
             <div className="row">
               <div className="col-md-6 form-group">
                 <input
                   type="text"
                   name="Name"
                   className="form-control"
                   id="name"
                   placeholder="Your Name"
                   required=""
                 />
               </div>
               <div className="col-md-6 form-group mt-3 mt-md-0">
                 <input
                   type="email"
                   className="form-control"
                   name="Email"
                   id="email"
                   placeholder="Your Email"
                   required=""
                 />
               </div>
             </div>
             <div className="form-group mt-3">
               <input
                 type="text"
                 className="form-control"
                 name="Contact"
                 id="Contact"
                 placeholder="Contact Number"
                 required=""
               />
             </div>
             <div className="form-group mt-3">
               <input
                 type="text"
                 className="form-control"
                 name="Subject"
                 id="subject"
                 placeholder="Subject"
                 required=""
               />
             </div>
             <div className="form-group mt-3">
               <textarea
                 className="form-control"
                 name="Message"
                 rows={5}
                 placeholder="Message"
                 required=""
                 defaultValue={""}
               />
             </div>
             <div className="text-center">
               <button id="submit-btn" type="submit" value="submit">
                 Send Message
               </button>
             </div>
           </form>
         </div>
       </div>
     </div>
   </section>
   {/* End Contact Section */}
 </main>
 </div>
  )
}

export default Home;

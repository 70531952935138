import React from "react";
import { Link } from 'react-router-dom';

function About() {
  return (
    <><div>
      {/* ======= Breadcrumbs ======= */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>About</h2>
            <ol>
              <li>
                <Link to="/home" style={{color:"#009970"}}>Home</Link>
              </li>
              <li>About</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Breadcrumbs */}</div><div className="mt-5 pt-3">
        {/* ======= About Section ======= */}
        <section id="about" className="about">
          <div className="container">
            <div className="row content">
              <div
                className="col-lg-6"
                data-aos="fade-right"
                data-aos-delay={100}
              >
                <h2>About Us</h2>
                <h3>Get to know who we really are</h3>
                <img src="assets/img/IDS_logo.png" alt="logo" />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0"
                data-aos="fade-left"
                data-aos-delay={200}
              >
                <p>
                  Welcome to the Indian Drivers Society, a community established
                  in 2017 by the able leadership Mr Nagarajan. With the core
                  objective of enhancing road safety, advocating for drivers'
                  rights, and fostering a culture of responsible driving across
                  India. Our society is composed of individuals from diverse
                  backgrounds united by a shared commitment to promoting safer
                  roads and supporting drivers nationwide.
                </p>

                <p>
                  Through our website, you can learn about our origins, mission,
                  and the dedicated team driving our initiatives forward. We offer
                  valuable resources such as comprehensive road safety tips
                  tailored to Indian driving conditions, information on drivers'
                  rights, and access to exclusive membership benefits. Stay
                  updated on our upcoming events, workshops, and campaigns aimed
                  at raising awareness and advocating for improved road
                  infrastructure.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* End About Section */}
        <div className="container-fluid guide py-5">
          <div className="container py-5">
            <div className="mx-auto text-center mb-5" style={{ maxWidth: 900 }}>
              <h5 className="section-title px-3">OUR TEAM</h5>
              <h1 className="mb-0">Board of Directors</h1>
            </div>
            <div className="row g-4 justify-content-center">
              <div className="col-md-6 col-lg-3">
                <div className="guide-item">
                  <div className="guide-img">
                    <div className="guide-img-efects">
                      <img
                        src="assets/img/Thenmala_Rajan.jpg"
                        className="img-fluid w-100 rounded-top"
                        alt="Thenmala Rajan" />
                    </div>
                  </div>
                  <div className="guide-title text-center rounded-bottom p-4">
                    <div className="guide-title-inner">
                      <h4 className="mt-3">Mr.Naga Rajan (Thenmala Rajan)</h4>
                      <p className="mb-0">Secretary</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></>
  );
}

export default About;

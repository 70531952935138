import { createSlice } from "@reduxjs/toolkit";

const vehicleSlice = createSlice({
  name: "vehicleList",
  initialState: {
    loading: false,
    vehicleList: {},
    isVehicleCreated: false,
    isVehicleDeleted: false,
    isVehicleUpdated: false,
  },
  reducers: {
    vehicleRequest(state, action) {
      return {
        loading: true,
      };
    },
    vehicleSuccess(state, action) {
      return {
        loading: false,
        vehicleList: action.payload.vehicleReg,
      };
    },
    vehicleFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    newVehicleRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newVehicleSuccess(state, action) {
      return {
        ...state,
        loading: false,
        vehicleList: action.payload.vehicleReg,
        isVehicleCreated: true,
      };
    },
    newVehicleFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isVehicleCreated: false,
      };
    },
    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearProduct(state, action) {
      return {
        ...state,
        vehicleList: {},
      };
    },
    updateVehicleRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateVehicleSuccess(state, action) {
      return {
        ...state,
        loading: false,
        vehicleList: action.payload.vehicleReg,
        isVehicleUpdated: true,
      };
    },
    updateVehicleFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearVehicleUpdated(state, action) {
      return {
        ...state,
        isVehicleUpdated: false,
      };
    },
    deleteVehicleRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteVehicleSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isVehicleDeleted: true,
      };
    },
    deleteVehicleFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearVehicleDeleted(state, action) {
      return {
        ...state,
        isVehicleDeleted: false,
      };
    },
  },
});

const { actions, reducer } = vehicleSlice;

export const {
  vehicleRequest,
  vehicleSuccess,
  vehicleFail,
  newVehicleRequest,
  newVehicleSuccess,
  newVehicleFail,
  clearError,
  clearProduct,
  updateVehicleRequest,
  updateVehicleSuccess,
  updateVehicleFail,
  clearVehicleUpdated,
  deleteVehicleRequest,
  deleteVehicleSuccess,
  deleteVehicleFail,
  clearVehicleDeleted,
} = actions;

export default reducer;



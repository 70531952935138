import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import { useDispatch, useSelector } from "react-redux";
import { getVehicleList } from "../../../actions/vehicleActions";
import { Dropdown } from 'react-bootstrap';
import { useNavigate, Link } from "react-router-dom";
import Loader from "../../layouts/Loader";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BsPersonFill } from 'react-icons/bs';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import { clearError } from '../../../slices/vehicleSlice';

function VehiclesList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vehicles, loading, error, vehiclesCount, resPerPage } =
    useSelector((state) => state.vehiclesState);
    const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 3000,
        onClose: () => dispatch(clearError()),
      });
    }
    dispatch(getVehicleList(null, currentPage));
  }, [dispatch,currentPage,error]);
  const setCurrentPageNo = (pageNo) => {
    setCurrentPage(pageNo);
  };
  return (
    <div className="container-fluid">
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Logo */}
          <div className="logo">
            <h1 className="text-light">IndianDriversSociety</h1>
          </div>

          {/* Profile Dropdown */}
          <Dropdown alignRight>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className='dropdown-item'>
              <BsPersonFill className="profile-pic" />
              <span>ADMIN</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/c-panel">
                Profile <i className="bi bi-person"></i>
              </Dropdown.Item>
              <Dropdown.Item href="/AdminLogin">
                Logout <i className="bi bi-box-arrow-right"></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className="row">
        {/* Sidebar */}
        <div className="col-lg-3 sidebar">
          <div className="profile">
            <h1 className='text-center bold'>Vehicles List</h1>
            {/* <img src="/assets/img/profile.jpg" alt="Profile" /> */}
            {/* <h3>Thenmala Rajan</h3> */}
            <h4>Admin</h4>
            <p><span className="icon user-icon" style={{ color: "green" }}>Online</span></p>
          </div>
          <nav className='text-center dashboard-nav'>
            <ul>
              <li><Link to="/c-panel" >Dashboard</Link></li>
              <li><Link to="/c-panel/members_list">Membership List</Link></li>
              <li><Link to="/c-panel/vehicles_list" className='active'>Vehicles List</Link></li>
            </ul>
          </nav>
        </div>

        {/* Main Content */}
        <div className="col-lg-9 main-content">
          {loading ? (
            <Loader />
          ) : (
            <div className="table-container">
              <h1 id="vehicle">VEHICLE REGISTRATION</h1>
              <table>
                <thead>
                  <tr>
                    <th>Vehicle Id</th>
                    <th>Owner Name</th>
                    <th>Vehicle Number</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicles && vehicles.map((data, index) => (
                    <tr key={index}>
                      <td>{data._id}</td>
                      <td>{data.rcOwnerName}</td>
                      <td>{data.vehicleNumber}</td>
                      <td>
                        <button onClick={() => {
                          navigate(`/c-panel/vehicle/${data._id}`);
                        }}>View details</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
          )}
          {vehiclesCount > 0 && vehiclesCount > resPerPage && (
          <div className="d-flex justify-content-center mt-5">
            <Pagination
              activePage={currentPage}
              onChange={setCurrentPageNo}
              totalItemsCount={vehiclesCount}
              itemsCountPerPage={resPerPage}
              firstPageText={"First"}
              lastPageText={"Last"}
              itemClass={"page-item"}
              linkClass={"page-link"}
            />
          </div>
        )}
        </div>

        
      </div>
    </div>
  );
}

export default VehiclesList;

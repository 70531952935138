import React, { useEffect, useState } from 'react';
import './adminStyle.css';
import { clearAuthError, login } from "../../../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { toast } from 'react-toastify';

function AdminLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error, isAuthenticated } = useSelector(
        (state) => state.authState
    );

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/c-panel");
        }
        if (error) {
            console.log(error);
            toast(error, {
                position: "top-center",
                type: "error",
                onOpen: () => {
                    dispatch(clearAuthError);
                },
            });
        }
    }, [isAuthenticated, error, navigate, dispatch]);

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h2>Login</h2>
                <form onSubmit={handleLogin}>
                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Username"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <span className="icon user-icon"></span>
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <span className="icon eye-icon"></span>
                    </div>
                    <div className="form-links">
                        <Link to=" " style={{ color: "#009970" }}>
                            Forgot Password?
                        </Link>
                    </div>
                    <button type="submit" className="login-button" style={{ background: "#009970" }}>
                        Login
                    </button>
                    <div className="signup-link">
                        <span>Don't have an account? </span>
                        <Link to="/AdminRegister" style={{ color: "#009970" }}>
                            Get Started For Free!
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminLogin;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberList } from '../../../actions/memberActions';
import { Dropdown } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { useNavigate, Link } from "react-router-dom";
import Loader from '../../layouts/Loader';
import './Dashboard.css';
import { BsPersonFill } from 'react-icons/bs';
import { clearError } from '../../../slices/memberSlice';
import { toast } from "react-toastify";

function MembersList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { members, loading, error, membersCount, resPerPage } = useSelector(
    (state) => state.membersState
  );
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (error) {
      toast.error(error, {
        position: "bottom-center",
        autoClose: 3000,
        onClose: () => dispatch(clearError()),
      });
    }
    dispatch(getMemberList(null, currentPage));
  }, [dispatch, currentPage, error]);

  const setCurrentPageNo = (pageNo) => {
    setCurrentPage(pageNo);
  };

  return (
    <div className="container-fluid">
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Logo */}
          <div className="logo">
            <h1 className="text-light">IndianDriversSociety</h1>
          </div>

          {/* Profile Dropdown */}
          <Dropdown alignRight>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className='dropdown-item'>
              <BsPersonFill className="profile-pic" />
              <span>ADMIN</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/c-panel">
                Profile <i className="bi bi-person"></i>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/AdminLogin">
                Logout <i className="bi bi-box-arrow-right"></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className="row">
        {/* Sidebar */}
        <div className="col-lg-3 sidebar">
          <div className="profile">
            <h1 className='text-center bold'>Members List</h1>
            <h4>Admin</h4>
            <p><span className="icon user-icon" style={{ color: "green" }}>Online</span></p>
          </div>
          <nav className='text-center dashboard-nav'>
            <ul>
              <li><Link to="/c-panel">Dashboard</Link></li>
              <li><Link to="/c-panel/members_list" className='active'>Membership List</Link></li>
              <li><Link to="/c-panel/vehicles_list">Vehicles List</Link></li>
            </ul>
          </nav>
        </div>

        {/* Main Content */}
        <div className="col-lg-9">
          {loading ? (
            <Loader />
          ) : (
            <div className="table-container">
              <table>
                <thead>
                  <h1 id="member">MEMBERSHIP REGISTRATION</h1>
                  <tr>
                    <th>Member Id</th>
                    <th>Member Name</th>
                    <th>Member Info</th>
                    <th>District</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {members &&
                    members.map((data, index) => (
                      <tr key={index}>
                        <td>{data._id}</td>
                        <td>{data.drivername}</td>
                        <td>{data.contactNumber}</td>
                        <td>{data.district}</td>
                        <td>
                          <button onClick={() => {
                            navigate(`/c-panel/member/${data._id}`);
                          }}>View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        {membersCount > 0 && membersCount > resPerPage && (
          <div className="d-flex justify-content-center mt-5">
            <Pagination
              activePage={currentPage}
              onChange={setCurrentPageNo}
              totalItemsCount={membersCount}
              itemsCountPerPage={resPerPage}
              firstPageText={"First"}
              lastPageText={"Last"}
              itemClass={"page-item"}
              linkClass={"page-link"}
            />
          </div>
        )}
        </div>
      </div>
    </div>
  );
}

export default MembersList;


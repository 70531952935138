import React from 'react';
import './Dashboard.css';
import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { BsPersonFill } from 'react-icons/bs';

function Dashboard() {

  return (
    <div className="container-fluid">
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Logo */}
          <div className="logo">
            <h1 className="text-light">IndianDriversSociety</h1>
          </div>

          {/* Profile Dropdown */}
          <Dropdown alignRight>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className='dropdown-item'>
              <BsPersonFill className="profile-pic" />
              <span>ADMIN</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/c-panel">
                Profile <i className="bi bi-person"></i>
              </Dropdown.Item>
              <Dropdown.Item href="/AdminLogin">
                Logout <i className="bi bi-box-arrow-right"></i>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>
      <div className="row">
        {/* Sidebar */}
        <aside className="col-lg-3 sidebar">
          <div className="profile">
            <h1 className="text-center bold">Dashboard</h1>
            {/* <img src="assets/img/profile.jpg" alt="Profile" /> */}
            {/* <h3>Thenmala Rajan</h3> */}
            <h4>Admin</h4>
            <p><span className="icon user-icon" style={{ color: "green" }}>Online</span></p>
          </div>
          <nav className="text-center dashboard-nav">
            <ul>
              <li><Link to="/c-panel" className='active'>Dashboard</Link></li>
              <li><Link to="/c-panel/members_list">Membership List</Link></li>
              <li><Link to="/c-panel/vehicles_list">Vehicles List</Link></li>
            </ul>
          </nav>
        </aside>

        {/* Main Content */}
        <main className="col-lg-9 main-content">
          <div className="stats">
            <div className="stat-item">
              <h2>106</h2>
              <p>Members</p>
              <Link to="/c-panel/members_list" className="view">
                <i className="bi bi-eye"></i> View Members List
              </Link>
            </div>
            <div className="stat-item">
              <h2>59</h2>
              <p>Vehicles</p>
              <Link to="/c-panel/vehicles_list" className="view">
                <i className="bi bi-eye"></i> View Vehicles List
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
